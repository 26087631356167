const dev_api_endpoint = "https://ksmmicroservicesdev.keysight.com/"
const stag_api_endpoint = "https://ksmmicroservicestest.keysight.com/"
const stag_api_endpoint2 = "https://ksmmicroservicestest2.keysight.com/"
const stag_api_endpoint3 = "https://ksmmicroservicestest3.keysight.com/"
const stag_api_endpoint4 = "https://ksmmicroservicestest4.keysight.com/"
const prod_api_endpoint = "https://licensing-micro.software.keysight.com/"
const local_api_endpoint = "http://127.0.0.1:8080/"
const local_api_endpoint2 = "http://127.0.0.1:8001/"
const local_api_endpoint3 = "http://127.0.0.1:8002/"

let backendPath = process.env.REACT_APP_STAGE === 'prod' ? prod_api_endpoint
    : process.env.REACT_APP_STAGE === 'stag' ? stag_api_endpoint
        : process.env.REACT_APP_STAGE === 'stag2' ? stag_api_endpoint2
            : process.env.REACT_APP_STAGE === 'stag3' ? stag_api_endpoint3
                : process.env.REACT_APP_STAGE === 'stag4' ? stag_api_endpoint4
                    : process.env.REACT_APP_STAGE === 'local' ? local_api_endpoint
                        : process.env.REACT_APP_STAGE === 'local2' ? local_api_endpoint2
                            : process.env.REACT_APP_STAGE === 'local3' ? local_api_endpoint3
                                : dev_api_endpoint;

const urls = {
    sso: {
        SSO_WELCOME_URL: "saml2_auth/sso/",
        JWT_REFRESH_URL: "api/token/refresh/",
        JWT_VERIFY_URL: "api/token/verify/"
    },
    welcome_screen: {
        DOWNTIME: "downtime/",
        CARDS_ENDPOINT: "welcome/tiles/"
    },
    certificate_module: {
        VIEW_CERTIFICATES: "external/user/view-certificates/",
        PRODUCT_DETAILS: "common-service/order/get-product-details/",
        EXCEL_EXPORT: "common-service/user-certificates/do-excel-export/",
        VIEW_ORDER_PDF: "common-service/certificate/get-content-details/"
    },
    add_certificate: {
        ORDER_DETAILS: "order/validate",
        ADD_CERTIFICATE: "profile"
    },
    request_license: {
        PRODUCT_LICENSES: "external/user/get-available-licenses/",
        HOST_TYPE_DETAILS: "common-service/product/get_host_type_details/",
        HOST_VALIDATION_REGEX: "common-service/host-id-type/validate-pattern/",
        HOST_FILE_UPLOAD: "common-service/host_file_upload/",
        REQUEST_NEW_LICENSE: "external/user/request-new-licenses/",
        REQUEST_NEW_LICENSE_MEDIAN: "external/user/request-new-licenses-median/",
        EMAIL_LICENSE: "external/user/email-licenses-to-customer/",
        DOWNLOAD_LICENSES: "external/user/download-licenses-to-customer/",
        EMAIL_SUPPORT: "external/user/send_error_email_to_support/",
        UPDATE_SUBSCRIPTION_TIE: 'external/user/update-subscription-tie/',
    },
    renew_nemo: {
        NEMO_VALIDATION: "common-service/nemo-file-upload/",
        REQUEST_RENEWAL: "common-service/nemo-renewal-request/",
    },
    software_updates: {
        SOFTWARE_SERVICE: "software/service/",
        GET_SOFTWARE_UPDATE: "external/user/get-software-update",
        GET_SOFTWARE_UPDATE_BY_PARAM: "external/user/get-software-update-by-param",
        GET_SOFTWARE_UPDATE_ITEM: "external/user/get-software-update-item",
        GET_SOFTWARE_KIT_ITEM: "external/user/get-software-kit-item",
        CREATE_KIT_ORDER: "external/user/create-kit-order",
        GET_SOFTWARE_LICENSE_ITEM: "external/user/get-software-license-item",
        CREATE_LICENSE_ORDER_REQUEST: "external/user/create-license-order-request/",
        GET_SOFTWARE_UPDATE_URL: "external/user/get-presigned-download-url"
    },
    renew_standalone: {
        RENEWALS: "external/user/get-standalone-subscription/",
        RENEWAL_PRODUCTS: "external/user/get-standalone-subscription_host/",
        REQUEST_RENEWAL: "external/user/renew-standalone-subscription/"
    },
    renew_eesof: {
      RENEWALS: "external/user/eesof-renewal-licenses/",
      REQUEST_RENEWAL: "external/user/eesof-renewal-license-request/",
    },
    deactivate_license: {
        SEARCH_DEACTIVATE: "common-service/search-deactivate-licenses/",
        GENERATE_LICENSE: "common-service/generate-deactivate-licenses/",
        VALIDATE_CODE: 'common-service/validate-confirmation-code/'
    },
    sync_license: {
        SEARCH_SYNC: "common-service/search-sync-licenses/",
        GENERATE_LICENSE: "common-service/generate-sync-licenses/",
    },
    view_by_product: {
        PRODUCT_LIST: 'external/user/license-summary-by-product/',
        PRODUCT_ID_SUMMARY_LIST: 'external/user/license-summary-by-product-id/',
        DOWNLOAD_LICENSE: 'common-service/license-files/get-download-links-for-product-host-id/',
        EXPORT_REPORT_RECORDS: 'external/user/license-summary/get-excel-report-records/',
        HOST_LIST: 'external/user/license-summary-by-host/',
        HOST_ID_SUMMARY_LIST: 'external/user/license-summary-by-host-id/',
        DOWNLOAD_HOST_LICENSE: 'common-service/license-files/get-download-links-for-host-ids/'
    },
    view_expiring_license_support: {
        LICENSES_SUPPORT: 'external/user/license-summary-expired'
    },
    ea_summary: {
        GET_ENTERPRISE_AGREEMENTS: 'ea/',
        COPY_PRODUCTS: 'ea/allocations/',
        PRE_SIGN_URL: 'external/user/get-presigned-download-url'
    },
    host_config: {
        HOSTS: 'host/alias/',
        LICENSES: 'host/licenses/',
        HOST_SERVICE: 'host/',
    },
    ea_details:{
        VIEW_DATA: 'ea/view/',
    },
    ea_request_license: {
        ON_LOAD: 'ea/view/',
        REQUEST_AGAIN_ON_LOAD: 'ea/license/request-again',
        SEARCH: 'ea/search/',
        HOST: 'host/',
        SAVE_DELETE: 'ea/allocations/',
        SUBSCRIPTION_POOL: 'ea/pool/',
        REQUEST_NEW_LICENSE: 'license/service/'
    },
    ea_dashboard: {
        EA: 'ea/dashboard/'
    },
    trial_license: {
        GUEST_API: 'license/public'
    },
    transport: {
        ON_LOAD: 'license/transport',
        GUEST_API: 'license/public',
    },
    software_access: {
        SOFTWARE_ACCESS: 'software/access',
    },
    prompts: {
        GET_PROMPTS: 'admin/prompt/',
        ACK_RESPONSE: 'external/ack/'
    },
    utilities: {
        health: "health/",
    },
    environment: {
        local: {
            keysight: "https://stgwww.keysight.com/",
            keysightSupport: "https://stgsupport.keysight.com/",
            s3_content: 'https://keysight-software-manager-content.s3.us-west-2.amazonaws.com/',
            US_NAVIGATION_URL: 'https://ksmtest.software.keysight.com/ASMProjects/ASM.7.2US/ASM/external/ui/welcome.aspx',
            LEGACY_ASM: 'https://ksmtest.software.keysight.com/ASM/External/'
        },
        dev: {
            keysight: "https://stgwww.keysight.com/",
            keysightSupport: "https://stgsupport.keysight.com/",
            s3_content: 'https://keysight-software-manager-content.s3.us-west-2.amazonaws.com/',
            US_NAVIGATION_URL: 'https://ksmtest.software.keysight.com/',
            LEGACY_ASM: 'https://ksmtest.software.keysight.com/ASM/External/'

        },
        stag: {
            keysight: "https://stgwww.keysight.com/",
            keysightSupport: "https://stgsupport.keysight.com/",
            s3_content: 'https://keysight-software-manager-content.s3.us-west-2.amazonaws.com/',
            US_NAVIGATION_URL: 'https://ksmtest.software.keysight.com/ASMProjects/ASM.7.2US/ASM/external/ui/welcome.aspx',
            LEGACY_ASM: 'https://ksmtest.software.keysight.com/ASM/External/'
        },
        stag2: {
            keysight: "https://stgwww.keysight.com/",
            keysightSupport: "https://stgsupport.keysight.com/",
            s3_content: 'https://keysight-software-manager-content.s3.us-west-2.amazonaws.com/',
            US_NAVIGATION_URL: 'https://ksmtest.software.keysight.com/ASMProjects/ASM.7.2US/ASM/external/ui/welcome.aspx',
            LEGACY_ASM: 'https://ksmtest.software.keysight.com/ASM/External/'
        },
        stag3: {
            keysight: "https://stgwww.keysight.com/",
            keysightSupport: "https://stgsupport.keysight.com/",
            s3_content: 'https://keysight-software-manager-content.s3.us-west-2.amazonaws.com/',
            US_NAVIGATION_URL: 'https://ksmtest.software.keysight.com/ASMProjects/ASM.7.2US/ASM/external/ui/welcome.aspx',
            LEGACY_ASM: 'https://ksmtest.software.keysight.com/ASM/External/'
        },
        stag4: {
            keysight: "https://stgwww.keysight.com/",
            keysightSupport: "https://stgsupport.keysight.com/",
            s3_content: 'https://keysight-software-manager-content.s3.us-west-2.amazonaws.com/',
            US_NAVIGATION_URL: 'https://ksmtest.software.keysight.com/ASMProjects/ASM.7.2US/ASM/external/ui/welcome.aspx',
            LEGACY_ASM: 'https://ksmtest.software.keysight.com/ASM/External/'
        },
        prod: {
            keysight: "https://keysight.com/",
            keysightSupport: "https://support.keysight.com/",
            s3_content: 'https://keysight-software-manager-content.s3.us-west-2.amazonaws.com/',
            US_NAVIGATION_URL: 'https://ksmlic.software.keysight.com/',
            LEGACY_ASM: 'https://ksm.software.keysight.com/ASM/External/'
        }
    }
};

let config = {}

const configUrls = () => {
    Object.keys(urls).forEach(pageKey => {
        if (pageKey === 'environment') {
            const environment = process.env.REACT_APP_STAGE
            if (urls[pageKey].hasOwnProperty(environment)) {
                Object.keys(urls[pageKey][environment]).forEach(urlKey => {
                    config[urlKey] = urls[pageKey][environment][urlKey]
                })
            }
        } else {
            if (!config.hasOwnProperty(pageKey)) {
                config[pageKey] = {}
            }
            Object.keys(urls[pageKey]).forEach(urlKey => {
                config[pageKey][urlKey] = backendPath + urls[pageKey][urlKey]
            })
        }
    })
}
configUrls()

export default config